import React, { useMemo } from 'react'
import { useLocation } from 'react-router'
import { getRTKQueryErrorMessage, useTrackingQuery } from '@customers-api-rtk-query'
import { bp, ContentLayout } from '@customers-ui'
import styled from '@emotion/styled'
import type { TrackingRequest } from '@extend-myextend/types'
import { SplashScreen } from '../../components/common'
import WismoContent from './components/wismo-content'
import { customLogger } from '@extend/client-helpers'
import ErrorPage from '@src/components/common/error-page'

const Tracking = () => {
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const orderId = queryParams.get('orderId') || ''
  const trackingNumber = queryParams.get('trackingNumber') || ''
  const carrierCode: TrackingRequest['carrierCode'] = (queryParams.get('carrierCode') ||
    '') as TrackingRequest['carrierCode']

  const {
    data: trackingData,
    isLoading: isTrackingLoading,
    isError,
    error,
  } = useTrackingQuery({ trackingNumber, carrierCode, orderId }, { skip: !trackingNumber || !carrierCode || !orderId })

  if (isTrackingLoading) return <SplashScreen data-cy="splash-screen" />

  if (isError) {
    const errorMessage = getRTKQueryErrorMessage(error)

    customLogger.warn(`[Err: Tracking Page] - ${errorMessage}`, {
      isError: isError || 'No RequestError',
    })

    return (
      <ErrorPage />
    )
  }

  return trackingData ? (
    <ContentLayout>
      <TrackingContentWrapper>
        <WismoContent trackingData={trackingData} carrierCode={carrierCode} data-cy="wismo-content" />
      </TrackingContentWrapper>
    </ContentLayout>
  ) : (
    <ErrorPage />
  )
}

const TrackingContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  marginTop: 56,
  [bp.mobile]: {
    marginTop: 24,
  },
})

export { Tracking }
