enum Channel {
  email = 'consumerPortalViaEmailCode',
  phone = 'consumerPortalViaSms',
}

function getChannel(inputType: 'email' | 'phone'): Channel {
  return inputType === 'email' ? Channel.email : Channel.phone
}

export function buildChannelName(inputType: 'email' | 'phone', brand?: string): string {
  if (brand) {
    return `${getChannel(inputType)}_${brand}`
  }
  return getChannel(inputType)
}
