import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import type { PlanTermsGetUrlsResponse } from '@extend-plans/types'
import type { Contract, Plan, PlanTermsSetResponse } from '@customers-api-client'
import { useLazyGetPlanTermsSetQuery, useLazyGetPlanTermsDocumentsQuery } from '@customers-api-rtk-query'
import { determineLocale } from '../router/utils'

export function usePlanTermsData({ contract, plan }: { contract?: Contract; plan?: Plan }): {
  terms: string | PlanTermsSetResponse | undefined
  isLoading: boolean
  error: unknown
} {
  const { pathname } = useLocation()
  const locale = determineLocale(pathname)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [getPlanTermsSet, { data: planTermsSets, error: hasPlanTermsSetError }] = useLazyGetPlanTermsSetQuery()

  const [getPlanTermsVersion, { data: planTermsVersion, error: hasPlanTermsVersionError }] =
    useLazyGetPlanTermsDocumentsQuery()

  const plansTermsLink = getPlansTermsLink({ planTermsVersion, locale })

  useEffect(() => {
    const { termsId: contractTermsId, termsVersion: contractTermsVersion } = contract?.plan || {}
    const { termsId: planTermsId, termsVersion: planTermsVersion } = plan || {}

    const toFetchTermsId = contractTermsId || planTermsId

    const getPlanTermsSets = async (): Promise<void> => {
      if (toFetchTermsId) {
        try {
          const response = await getPlanTermsSet({
            termsId: toFetchTermsId,
          }).unwrap()
          if (response.length > 0) {
            setIsLoading(false)
          } else {
            await getPlanTerms()
            setIsLoading(false)
          }
        } catch (e) {
          await getPlanTerms()
          setIsLoading(false)
        }
      }
    }

    const getPlanTerms = async (): Promise<void> => {
      if (contractTermsId && contractTermsVersion) {
        try {
          await getPlanTermsVersion({
            termsId: contractTermsId,
            version: contractTermsVersion,
          })
        } catch (e) {
          console.error(e)
        }
      } else if (planTermsId && planTermsVersion) {
        try {
          await getPlanTermsVersion({
            termsId: planTermsId,
            version: planTermsVersion,
          })
        } catch (e) {
          console.error(e)
        }
      }
    }

    getPlanTermsSets()
  }, [contract, getPlanTermsSet, getPlanTermsVersion, plan])

  return {
    terms: planTermsSets || plansTermsLink,
    isLoading,
    error: hasPlanTermsSetError && hasPlanTermsVersionError,
  }
}

const getPlansTermsLink = ({
  planTermsVersion,
  locale,
}: {
  planTermsVersion?: PlanTermsGetUrlsResponse
  locale: string
}): string => {
  if (!planTermsVersion) {
    return ''
  }

  // Via Pre-Pex, plan terms languages are formatted as either 'en' or 'en-US', but we are moving toward the latter.
  // Most terms will only have one language available. In general, new language = new terms
  // Hence, it is recommended to match the locale from the url and fallback to the first item.
  const { languages } = planTermsVersion.versions[0]
  const plansTermsLocaleLink = languages?.find((item) => item.language === locale)?.url
  const plansTermDefaultLink = languages[0]?.url
  return plansTermsLocaleLink || plansTermDefaultLink || ''
}
