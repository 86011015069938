import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import { v4 as uuid } from 'uuid'
import type { FinishPopaRequest, FinishPopaResponse, StartPopaRequest, StartPopaResponse } from './types'
import { buildChannelName } from './utils'

export const authenticationApi = createApi({
  baseQuery,
  reducerPath: 'authentication',
  tagTypes: ['authentication'],
  endpoints: (build) => ({
    startPopa: build.mutation<StartPopaResponse, StartPopaRequest>({
      query: ({ contact, brand, inputType }) => {
        return {
          url: 'auth/passwordless/start/v1',
          method: 'POST',
          body: {
            contact,
            channel: buildChannelName(inputType, brand),
            session: uuid(),
          },
        }
      },
      invalidatesTags: ['authentication'],
    }),
    finishPopa: build.mutation<FinishPopaResponse, FinishPopaRequest>({
      query: ({ contact, brand, inputType, session, response }) => {
        return {
          url: 'auth/oauth/token',
          method: 'POST',
          body: {
            grant_type: 'http://api.helloextend.com/auth/oauth/grant-type/popa',
            contact,
            channel: buildChannelName(inputType, brand),
            session,
            response,
          },
        }
      },
      invalidatesTags: ['authentication'],
    }),
  }),
})

export const { useStartPopaMutation, useFinishPopaMutation } = authenticationApi
