import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAccessToken, getDecodedAccessToken } from '../store/slices/my-extend'
import { setLogoutUser } from '../store/slices/my-extend'
import { isValidToken } from '../lib/security-utils/jwt'
import { reset as resetPersistedStorage } from '../store/persistence'

const ONE_MINUTE = 60 * 1000

export const useLogOutExpiredTokens = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const accessToken = useSelector(getAccessToken)
  const decodedAccessToken = useSelector(getDecodedAccessToken)

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (accessToken && decodedAccessToken) {
        if (!isValidToken(accessToken) && history) {
          // Token is bad, log out the user
          resetPersistedStorage()
          dispatch(setLogoutUser())
          // Redirect to the home page
          history.push('/')
        }
      }
    }

    // call once on page load
    checkTokenExpiration()

    // Set up interval to check every minute
    const interval = setInterval(checkTokenExpiration, ONE_MINUTE)

    // Clean up interval on unmount
    return () => {
      clearInterval(interval)
    }
  }, [accessToken, decodedAccessToken, dispatch, history])
}
