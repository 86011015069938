import type { EmsProduct, EmsProductWithShipmentInfo, OrdersListByShipmentsResponse } from '@customers-api-rtk-query'

export const getOrderProducts = (
  order: OrdersListByShipmentsResponse[0],
): Array<EmsProduct | EmsProductWithShipmentInfo> => {
  return [...Object.values(order.trackedProducts).flatMap((group) => group.products), ...order.untrackedProducts]
}

export const getIsShippingResolution = (order: OrdersListByShipmentsResponse[0]): boolean => {
  return getOrderProducts(order).some((product) =>
    product.entitlements?.some((entitlement) => entitlement.type === 'shipping_resolution'),
  )
}

export const getHasShippingEntitlement = (order: OrdersListByShipmentsResponse[0]): boolean => {
  return getOrderProducts(order).some((product) =>
    product.entitlements?.some((entitlement) =>
      ['shipping_protection', 'shipping_resolution'].includes(entitlement.type),
    ),
  )
}
