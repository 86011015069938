import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import type { ContractsSearchResponse } from '../../types/contract'
import { sortAndGroupContracts } from '../../lib/helper-functions'
import type { OrdersListByShipmentsResponse } from '@customers-api-rtk-query'
import { LDFlag } from '../../constants/ld-flags'
import { ContractList } from './contract-list'
import { OrdersList } from './orders-list'
import { useFlags } from 'launchdarkly-react-client-sdk'

type ContractListContainerProps = ContractsSearchResponse & {
  emsOrders: OrdersListByShipmentsResponse
}

const ContractListContainer: FC<ContractListContainerProps> = ({ contracts, emsOrders }) => {
  const { [LDFlag.UseMegaCard]: FF_USE_MEGA_CARD } = useFlags()
  const groupedContracts = contracts ? sortAndGroupContracts([...contracts]) : {}

  return (
    <ContractListWrapper data-cy="contracts-list-wrapper">
      {!FF_USE_MEGA_CARD && (!!contracts.length || !!emsOrders.length) && (
        <ContractList
          groupedContracts={groupedContracts}
          emsOrders={emsOrders}
          data-cy="contract-list-active"
        />
      )}
      {FF_USE_MEGA_CARD && emsOrders.length && <OrdersList emsOrders={emsOrders} />}
    </ContractListWrapper>
  )
}

const ContractListWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  [bp.mobile]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '16px',
  },
  [bp.desktop]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '24px',
  },
})

export { ContractListContainer }
