import type { FC } from 'react'
import React from 'react'
import IntlMessageFormat from 'intl-messageformat'
import styled from '@emotion/styled'
import type { PlanDetails } from '@customers-api-client'
import { COLOR } from '@customers-ui'
import { Section } from '../../../../components/warranty-terms-section'

export interface PlanSummaryProps {
  planDetails: PlanDetails | null
}

const PlanSummary: FC<PlanSummaryProps> = ({ planDetails }) => {
  if (!planDetails) return null

  const {
    planSummaryWhatTitle,
    planSummaryWhatBody,
    planSummaryWhenTitle,
    planSummaryWhenBody,
    planSummaryHowTitle,
    planSummaryHowBody,
    claimStartLinkText,
    claimStartTitle,
  } = planDetails.planTerms

  const intlMsg = new IntlMessageFormat(planSummaryHowBody)
  const planSummaryHowBodyWithLink = intlMsg.format({
    claimStartLink: (
      <a key={'claim-start-link'} href={claimStartLinkText} target="_blank" rel="noopener noreferrer">
        {claimStartTitle}
      </a>
    ),
  })

  return (
    <Section title={planDetails.planTermsLabels.planSummaryHeader}>
      <Title key={'what-title'}>{planSummaryWhatTitle}</Title>
      <Body key={'what-body'}>{planSummaryWhatBody}</Body>
      <Title key={'when-title'}>{planSummaryWhenTitle}</Title>
      <Body key={'when-body'}>{planSummaryWhenBody}</Body>
      <Title key={'how-title'}>{planSummaryHowTitle}</Title>
      <Body key={'how-body'}>{planSummaryHowBodyWithLink}</Body>
    </Section>
  )
}

const Title = styled.h3({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: 1.4,
  margin: '0 0 4px 0',
})

const Body = styled.p({
  marginTop: 0,
  fontSize: 18,
  '& h3': {
    marginTop: 40,
  },
  '& a': {
    textDecoration: 'underline',
    color: COLOR.BLUE[700],
  },
})

export { PlanSummary }
