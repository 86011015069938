import React from 'react'
import styled from '@emotion/styled'
import type { SanitizedEvent, TrackingResponse } from '@extend-myextend/types'
import { formatDate, formatTime } from '../date-formatting'
import WismoEventDay from './wismo-event-day'
import { ArrowDropDown, ArrowDropDownClose, Button } from '@extend/zen'

interface WismoEventsProps {
  trackingData: TrackingResponse
}

export interface DailyEventInfo {
  eventDescription: string
  city: string
  state: string
  timestamp: string
}

const WismoEvents: React.FC<WismoEventsProps> = ({ trackingData }) => {
  const [showFullEvents, setShowFullEvents] = React.useState(false)

  const eventsToShow = showFullEvents ? trackingData.events : [trackingData.events[0]]

  // Group daily event info based on the date
  const dailyEvents: { [key: string]: DailyEventInfo[] } = eventsToShow.reduce(
    (acc: { [key: string]: DailyEventInfo[] }, event: SanitizedEvent) => {
      // use the formatted date (Weekday, month, day) as the key to group event info by
      const timestamp = formatDate(event.occurredAt, false)
      // create a new array if the key doesn't already exist
      if (!acc[timestamp]) {
        acc[timestamp] = []
      }
      // push the DailyEventInfo into the group of events for that day
      acc[timestamp].push({
        eventDescription: event.description as string,
        city: event.cityLocality,
        state: event.stateProvince,
        timestamp: formatTime(event.occurredAt),
      })
      return acc
    },
    {},
  )

  return (
    <WismoEventsContainer>
      {Object.keys(dailyEvents).map((date, i) => {
        return (
          <WismoEventDay
            data-cy={`event-day-${i}`}
            key={`${date}-${i}`}
            date={date}
            dailyEventInfo={dailyEvents[date]}
          />
        )
      })}
      {trackingData.events.length > 1 && (
        <Button
          data-cy="all-updates-btn"
          text="All Updates"
          emphasis="low"
          icon={showFullEvents ? ArrowDropDownClose : ArrowDropDown}
          iconPosition="left"
          size="small"
          color="neutral"
          onClick={() => setShowFullEvents(!showFullEvents)}
        />
      )}
    </WismoEventsContainer>
  )
}

const WismoEventsContainer = styled.div({
  display: 'flex',
  padding: '16px 0px 16px 16px',
  flexDirection: 'column',
  gap: 16,
  flex: '1 0 0',
})

export default WismoEvents
