import { useCallback, useState } from 'react'
import { useFinishPopaChallengeMutation, useLazySearchConsumerProfileQuery } from '@customers-api-rtk-query'
import { useDispatch } from 'react-redux'
import type { AuthFinishContact } from '@customers-api-rtk-query'
import { useTheme } from '@emotion/react'
import type { UserInfo } from '../pages/myextend-authentication/myextend-authentication'
import { buildAuthPayload, buildPayload } from '../pages/myextend-authentication/utils'
import { getItem, ACCESS_TOKEN_KEY } from '../store/persistence'
import { setAccessToken, setConsumerProfile } from '../store/slices/my-extend'
import { isCodeInError, isFetchBaseQueryError } from '../lib/helper-functions'
import type { Locale } from '../types/localization'
import { useFinishPopaMutation } from '@customers-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '@src/constants/ld-flags'

export const TOAST_ERRORS: Record<string, string> = {
  invalid_challenge_response: 'Incorrect passcode entered. Please try again.',
  max_number_of_challenge_response_attempts: 'Exceeded maximum login attempts. Please request a new passcode.',
  challenge_expired: 'Your passcode has expired. Please request a new one.',
}

const RETURN_TO_USER_INFO_ERRORS = ['max_number_of_challenge_response_attempts', 'challenge_expired']

const useMyExtendPopaToken = (
  userInfo: UserInfo,
  sessionId: string,
): {
  popaToken: string | null
  errorType: string | null
  isToastError: boolean
  isReturnToUserInfoError: boolean
  isTokenLoading: boolean
  isSuccess: boolean
  requestPopaToken: (passcode: string, locale: Locale, isAuth?: boolean) => void
} => {
  const { [LDFlag.UseAuthPopa]: FF_USE_AUTH_POPA } = useFlags()
  const [popaToken, setPopaToken] = useState<string | null>(getItem(ACCESS_TOKEN_KEY) as string | null)
  const [getPopaToken, { isLoading: isMyExtendLoading }] = useFinishPopaChallengeMutation()
  const [getAuthPopaToken, { isLoading: isAuthLoading }] = useFinishPopaMutation()
  const [searchConsumerProfile] = useLazySearchConsumerProfileQuery()
  const [errorType, setErrorType] = useState<string | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isToastError, setIsToastError] = useState<boolean>(false)
  const [isReturnToUserInfoError, setIsReturnToUserInfoError] = useState<boolean>(false)
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isExtend, subdomainName } = theme.merchantConfiguration
  const isLoading = isMyExtendLoading || isAuthLoading

  const mapAuthError = async (error: unknown): Promise<void> => {
    if (isFetchBaseQueryError(error) && isCodeInError(error.data)) {
      const errorCode = error.data.code
      if (errorCode in TOAST_ERRORS) {
        setIsToastError(true)
        setErrorType(TOAST_ERRORS[errorCode])
        if (RETURN_TO_USER_INFO_ERRORS.includes(errorCode)) {
          setIsReturnToUserInfoError(true)
        }
      } else if (errorCode === 'max_number_of_challenge_requests') {
        setErrorType(errorCode)
      } else {
        setErrorType('authentication_failed')
      }
    } else {
      setErrorType('authentication_failed')
    }
  }

  const fetchConsumerProfile = useCallback(
    async (accessToken: string): Promise<void> => {
      try {
        const consumerProfile = await searchConsumerProfile({
          apiVersion: 'latest',
          accessToken,
        }).unwrap()
        dispatch(setConsumerProfile(consumerProfile))
      } catch (error) {
        if (isFetchBaseQueryError(error) && isCodeInError(error.data)) {
          const errorCode = error.data.code
          if (errorCode === 'profile_not_found') return
          setErrorType('authentication_failed')
        }
      }
    },
    [dispatch, searchConsumerProfile, setErrorType],
  )

  const requestPopaToken = useCallback(
    async (passcode: string, locale: Locale, isAuth = true): Promise<void> => {
      setIsReturnToUserInfoError(false)
      setIsToastError(false)
      setErrorType(null)

      try {
        if (FF_USE_AUTH_POPA) {
          const payload = buildAuthPayload(userInfo, locale, isExtend, subdomainName)
          const response = await getAuthPopaToken({
            ...payload,
            session: sessionId,
            response: passcode,
          }).unwrap()
          if (response.access_token) {
            // Only set token and fetch profile if this is for authentication (not verification)
            if (isAuth) {
              // TODO: [POST-3179] implement login endpoint
            }
            setIsSuccess(true)
          }
        } else {
          const payload = buildPayload(userInfo, locale, isExtend, subdomainName) as AuthFinishContact
          const response = await getPopaToken({
            body: {
              ...payload,
              sessionId,
              code: passcode,
            },
            apiVersion: 'latest',
          }).unwrap()

          if (response.token) {
            // Only set token and fetch profile if this is for authentication (not verification)
            if (isAuth) {
              await fetchConsumerProfile(response.token)

              dispatch(setAccessToken(response.token))
              setPopaToken(response.token)
            }
            setIsSuccess(true)
          }
        }
      } catch (error) {
        mapAuthError(error)
      }
    },
    [dispatch, userInfo, getAuthPopaToken, getPopaToken, sessionId, fetchConsumerProfile],
  )

  return {
    errorType,
    popaToken,
    isToastError,
    isReturnToUserInfoError,
    isTokenLoading: isLoading,
    isSuccess,
    requestPopaToken,
  }
}

export { useMyExtendPopaToken }
