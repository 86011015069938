import type { OrdersListByShipmentsResponse, PrecheckAllRequest } from '@customers-api-rtk-query'
import { usePrecheckAllQuery } from '@customers-api-rtk-query'
import { getOrderProducts } from '@src/lib/ems-utils'

export const usePrecheckEmsData = (order: OrdersListByShipmentsResponse[0]) => {
  const hasShippingResolution = getOrderProducts(order).some((product) =>
    product.entitlements?.some((entitlement) => entitlement.type === 'shipping_resolution'),
  )

  const queries: PrecheckAllRequest = []

  if (hasShippingResolution) {
    queries.push({ orderId: order.id })
  }
  if (order.hasContracts) {
    order.contracts?.forEach((contract) => {
      queries.push({ contractId: contract.id, contract })
    })
  }

  const { data } = usePrecheckAllQuery(queries)

  return {
    data,
  }
}
