import React, { useState, useEffect, useCallback } from 'react'
import type { FC } from 'react'
import { createPortal } from 'react-dom'
import { isMobile } from 'react-device-detect'
import {
  useCreateClaimPhotoMutation,
  useUploadClaimPhotoMutation,
  useGetClaimPhotosQuery,
} from '@customers-api-rtk-query'
import type { ModalButtonProps } from '@extend/zen'
import { Info, InlineAlert, InlineAlertColor, Modal, ModalController, COLOR } from '@extend/zen'
import styled from '@emotion/styled'
import { Toast, bp, Spinner } from '@customers-ui'
import type { Claim, ClaimPhotoDetail } from '../../types/claim'
import { logEvent } from '../../analytics'
import { PhotoInstruction } from './photo-instruction'
import type { CurrentRequirementState } from '../../lib/photo-requirements-utils'
import { getInitialPhotoRequirementsState, getNextPhotoRequirement } from '../../lib/photo-requirements-utils'

interface PhotoUploadModalProps {
  claim: Claim
  onDismiss: () => void
  isModalOpen: boolean
  handleResultModalOpen: () => void
}

const PhotoUploadModal: FC<PhotoUploadModalProps> = ({ claim, onDismiss, isModalOpen, handleResultModalOpen }) => {
  const [uploadPhotoId, setUploadPhotoId] = useState<string>('')
  const [secondsLeft, setSecondsLeft] = useState<number>(10)
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false)
  const [isUploadingPhotos, setIsUploadingPhotos] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [shouldFetchPhotos, setShouldFetchPhotos] = useState(false)
  const [currentImage, setCurrentImage] = useState<File>()
  const [isErrorToastVisible, setIsErrorToastVisible] = useState(false)
  const [isPhotoRejected, setIsPhotoRejected] = useState(false)
  const [currentRequirement, setCurrentRequirement] = useState<CurrentRequirementState | null>(null)

  const [createPhoto, { error }] = useCreateClaimPhotoMutation()
  const [uploadPhoto] = useUploadClaimPhotoMutation()
  const { data: claimPhotosData } = useGetClaimPhotosQuery(
    { claimId: claim.id, filterStatuses: ['saved', 'rejected'] },
    {
      skip: !isModalOpen || !shouldFetchPhotos,
      selectFromResult: (response) => ({
        ...response,
        data: response.data ? response.data.photoDetails : null,
      }),
      pollingInterval: 1000,
    },
  )

  useEffect(() => {
    if (isModalOpen) {
      if (!shouldFetchPhotos && !claimPhotosData) {
        setShouldFetchPhotos(true)
        setIsLoading(true)
      }

      if (shouldFetchPhotos && !currentRequirement && claimPhotosData && !uploadPhotoId) {
        setShouldFetchPhotos(false)
        setCurrentRequirement(getInitialPhotoRequirementsState(claim, claimPhotosData))
        setIsLoading(false)
      }

      if (currentRequirement && currentRequirement.index > 0) {
        setIsUploadingPhotos(true)
      }

      if (error && !isErrorToastVisible) {
        setIsErrorToastVisible(true)
      }
    }
  }, [
    isModalOpen,
    error,
    isErrorToastVisible,
    shouldFetchPhotos,
    claimPhotosData,
    isLoading,
    uploadPhotoId,
    claim,
    currentRequirement,
  ])

  const handleLoadingReset = (): void => {
    setShouldFetchPhotos(false)
    setIsLoading(false)
    setIsTimerRunning(false)
    setSecondsLeft(10)
    setUploadPhotoId('')
  }

  // Timer to manage 10 second countdown to move to next photo
  useEffect(() => {
    if (!secondsLeft || !isTimerRunning) return

    const intervalId = setInterval(() => {
      setSecondsLeft(secondsLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [secondsLeft, isTimerRunning])

  // Checks each second to see if the photo has been uploaded
  // If the photo has been uploaded or ten seconds have passed, move on to the next photo
  // Unless it it the last photo, then wait as long as needed
  const handlePhotoUploadStatus = useCallback(
    (claimPhoto?: ClaimPhotoDetail): void => {
      // manage `rejected` photos
      if (claimPhoto?.status === 'rejected') {
        setIsPhotoRejected(true)
        handleLoadingReset()
        return
      }

      // manage `saved` photos
      if (claimPhoto?.status === 'saved') {
        handleLoadingReset()
        // If this was the last photo, move on to the result modal
        if (currentRequirement?.isLastRequirement) {
          onDismiss()
          handleResultModalOpen()
          // if this is not the last photo, move on to the next photo
        } else if (currentRequirement) {
          setCurrentRequirement(
            getNextPhotoRequirement(claim.photoRequirements ?? [], currentRequirement, claimPhotosData ?? []),
          )
        }
        return
      }

      const isTimerZero = secondsLeft <= 0

      // If the timer has run out, and there are more photos to upload, move on to the next photo
      if (isTimerZero && currentRequirement && !currentRequirement?.isLastRequirement) {
        handleLoadingReset()
        setCurrentRequirement(
          getNextPhotoRequirement(claim.photoRequirements ?? [], currentRequirement, claimPhotosData ?? []),
        )
        return
      }

      // For the last photo, wait for a `saved` or `rejected` status on the photo, no matter how long it takes
      if (isTimerZero && currentRequirement?.isLastRequirement) {
        setSecondsLeft(secondsLeft + 2)
        return
      }
    },
    [claim, currentRequirement, handleLoadingReset, handleResultModalOpen, onDismiss, secondsLeft],
  )

  // Every second while the time is running, check the status of the photo
  useEffect(() => {
    if (isTimerRunning) {
      const claimPhoto = claimPhotosData?.find((photo) => photo.id === uploadPhotoId)
      handlePhotoUploadStatus(claimPhoto)
    }
  }, [secondsLeft, isTimerRunning])

  const handleUploadPhotos = (): void => {
    setIsUploadingPhotos(true)
  }

  const handleImageChange = (photo: File): void => {
    setIsPhotoRejected(false)
    setCurrentImage(photo)
  }

  const handleSaveAndContinue = async (): Promise<void> => {
    if (!currentImage) return

    setIsLoading(true)
    setSecondsLeft(10)
    setIsTimerRunning(true)
    try {
      const createClaimPhotoResponse = await createPhoto({
        claimId: claim.id,
        body: {
          source: 'chatbot',
          description: currentRequirement?.requirement.description as string,
          requirement: currentRequirement?.requirement,
        },
      }).unwrap()

      const { photoId, ...presignedPost } = createClaimPhotoResponse

      const { url, fields } = presignedPost
      const formData = new FormData()
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value as string)
      })
      formData.append('file', currentImage)
      try {
        setUploadPhotoId(photoId)
        setShouldFetchPhotos(true)
        await uploadPhoto({ url, body: formData }).unwrap()
      } catch (err: unknown) {
        // There can be CORS errors despite successfully uploading
        if (
          (err as { status: string; error: string }).status !== 'FETCH_ERROR' ||
          (err as { status: string; error: string }).error !==
            'TypeError: NetworkError when attempting to fetch resource.'
        ) {
          setCurrentImage(undefined)
          return
        }
        throw err
      }

      setCurrentImage(undefined)
    } catch (err: unknown) {
      setIsErrorToastVisible(true)
    }
  }

  const handleSubmit = (): void => {
    if (!currentRequirement?.isLastRequirement) {
      return
    }
    logEvent('Photo Upload Submit Claim - Click', 'Submit')
    handleSaveAndContinue()
  }

  const initialButtonProps: { [key: string]: ModalButtonProps } = {
    primary: {
      text: 'Continue',
      onClick: handleUploadPhotos,
      emphasis: 'high',
      'data-cy': 'photo-instructions-modal-continue-button',
    },
    secondary: {
      text: 'Exit',
      onClick: onDismiss,
      emphasis: 'medium',
      'data-cy': 'photo-instructions-modal-exit-button',
    },
  }

  const uploadingButtonProps: { [key: string]: ModalButtonProps } = {
    primary: {
      text: currentRequirement?.isLastRequirement ? 'Submit' : 'Save and continue',
      onClick: currentRequirement?.isLastRequirement ? handleSubmit : handleSaveAndContinue,
      emphasis: 'high',
      'data-cy': 'photo-upload-save-continue-button',
      isDisabled: isLoading || !currentImage,
    },
    secondary: {
      text: 'Exit',
      onClick: onDismiss,
      emphasis: 'medium',
      'data-cy': 'photo-upload-exit-button',
    },
  }

  const handleReload = (): void => {
    window.location.reload()
  }

  return createPortal(
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading={isUploadingPhotos ? 'Upload Photos' : 'Photo Instructions'}
        size="sm"
        onDismissRequest={onDismiss}
        primaryButtonProps={isUploadingPhotos ? uploadingButtonProps.primary : initialButtonProps.primary}
        secondaryButtonProps={isUploadingPhotos ? uploadingButtonProps?.secondary : initialButtonProps.secondary}
        isMobile={isMobile}
        maxHeight={!isMobile ? '80vh' : undefined}
        data-cy="photo-upload-modal"
        hasBodyPadding
      >
        <ContentWrapper data-cy="photo-upload-content-wrapper">
          <Toast
            message="We've encountered an error. Please refresh this page."
            type="danger"
            isVisible={isErrorToastVisible}
          >
            <RefreshButton onClick={handleReload}>Refresh</RefreshButton>
          </Toast>
          {isLoading && (
            <SpinnerLoading data-cy="spinner-loading">
              <Spinner size="lg" />
            </SpinnerLoading>
          )}
          {!isUploadingPhotos &&
            !isLoading &&
            claim?.photoRequirements?.map((item, index) => {
              return <PhotoInstruction key={item.id} photoRequirement={item} index={index} />
            })}
          {isUploadingPhotos && currentRequirement && !isLoading && (
            <PhotoInstruction
              photoRequirement={currentRequirement?.requirement}
              index={currentRequirement?.index}
              onChange={handleImageChange}
              isUploadingPhotos={isUploadingPhotos}
              isPhotoRejected={isPhotoRejected}
            />
          )}
          <InlineAlert data-cy="attachments-inline-alert" color={InlineAlertColor.yellow} icon={Info}>
            Please do not submit photos that depict people or other personally-identifying info
          </InlineAlert>
        </ContentWrapper>
      </Modal>
    </ModalController>,
    document.body,
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
})

const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
  width: '100%',
  height: '252px',
})

const RefreshButton = styled.button({
  [bp.mobile]: {
    display: 'none',
  },
  [bp.desktop]: {
    display: 'flex',
    backgroundColor: COLOR.RED['200'],
    color: COLOR.RED['700'],
    fontSize: 14,
    borderRadius: 25,
    border: `1px solid ${COLOR.RED['700']}`,
    padding: '7px 26px',
    lineHeight: '19px',
    fontWeight: 700,
    marginLeft: 44,
  },
  '&:hover': {
    cursor: 'pointer',
  },
})

export { PhotoUploadModal }
