import type {
  CategoryContract,
  ContractStatus,
  ContractsSearchIndividual,
  ExtendedWarrantyContract,
} from '../../types/contract'
import type { Claim } from '../../types/claim'
import type { EmsContract, EmsEntitlement } from '@customers-api-rtk-query'

export const NON_TRANSFERABLE_STATUSES: ContractStatus[] = [
  'cancelled',
  'expired',
  'refund_accepted',
  'refund_paid',
  'refund_requested',
  'voided',
  'terminated',
]

export const getCanTransfer = ({
  contract,
  emsContract,
  emsCoverage,
  claims,
  hasActiveClaim,
}: {
  contract?: ContractsSearchIndividual
  emsContract?: EmsContract
  emsCoverage?: EmsEntitlement['remainingCoverage']
  claims?: Claim[]
  hasActiveClaim?: boolean
}): boolean => {
  if (contract) {
    if (
      new Date().getTime() <
      (((contract as ExtendedWarrantyContract) || (contract as CategoryContract)).coverage?.starts || 0)
    ) {
      return false
    }

    if (NON_TRANSFERABLE_STATUSES.includes(contract.status)) {
      return false
    }

    if (contract.purchaseCurrency === 'CAD') {
      return false
    }
  }

  if (emsContract && emsCoverage) {
    if (new Date().getTime() < (emsCoverage.startDate || 0)) {
      return false
    }

    if (NON_TRANSFERABLE_STATUSES.includes(emsContract.status)) {
      return false
    }

    // @ts-expect-error type is incorrect
    if (emsContract.currency === 'CAD') {
      return false
    }
  }

  if (hasActiveClaim) {
    return false
  }

  return !claims?.some(
    (claim: Claim) =>
      claim.status === 'approved' || claim.status === 'review' || claim.status === 'pending_adjudication',
  )
}
