import type { AuthFinishContact, AuthStartRequest } from '@customers-api-rtk-query'
import { formatPhoneNumberIso } from '@extend/client-helpers'
import type { Locale } from '../../types/localization'
import type { UserInfo } from './myextend-authentication'
import type { StartPopaRequest } from '@customers-api-rtk-query'

export function buildPayload(
  userInfo: UserInfo,
  locale: Locale,
  isExtend: boolean,
  subdomainName: string,
): AuthStartRequest | AuthFinishContact {
  const { email, phoneNumber } = userInfo

  if (email)
    return {
      email,
      ...(!isExtend && { brand: subdomainName }),
    }
  if (phoneNumber) {
    const [, country] = locale.split('-')
    return {
      phoneNumber: formatPhoneNumberIso(phoneNumber, country),
      ...(!isExtend && { brand: subdomainName }),
    }
  }
  throw new Error('unexpected user info missing to build payload')
}

export function buildAuthPayload(
  userInfo: UserInfo,
  locale: Locale,
  isExtend: boolean,
  subdomainName: string,
): StartPopaRequest {
  const { email, phoneNumber } = userInfo

  if (email)
    return {
      contact: email,
      ...(!isExtend && { brand: subdomainName }),
      inputType: 'email',
    }
  if (phoneNumber) {
    const [, country] = locale.split('-')
    return {
      contact: formatPhoneNumberIso(phoneNumber, country),
      ...(!isExtend && { brand: subdomainName }),
      inputType: 'phone',
    }
  }
  throw new Error('unexpected user info missing to build payload')
}

