import React from 'react'
import styled from '@emotion/styled'
import { Subheading, COLOR, Icon, Package, Badge } from '@extend/zen'
import { isMobile } from 'react-device-detect'
import { bp } from '@src/lib/customers-ui'
import type { DisplayDetails } from '@src/lib/helper-functions'
import { mapContractStatusBadge } from '@src/lib/helper-functions'
import { getIsShippingResolution } from '@src/lib/ems-utils'
import { toTitleCase } from '@src/utils/string-utils'
import type { OrdersListByShipmentsResponse } from '@extend-myextend/types'

export const ShippingProtectionBadgePreHeading = ({
  isLoading,
  status,
  shipmentDisplayDetails,
  hasActiveShipmentClaim,
  order,
}: {
  isLoading: boolean
  status?: string
  shipmentDisplayDetails: DisplayDetails
  hasActiveShipmentClaim: boolean
  order: OrdersListByShipmentsResponse[0]
}) => {
  const firstTrackingProduct = Object.values(order.trackedProducts).find(({ products }) =>
    products.some((product) => product.tracking?.status),
  )?.products
  const shippingStatus = firstTrackingProduct?.length ? firstTrackingProduct[0].tracking?.status : undefined
  const shippingResolutionBadgeText = shippingStatus ? toTitleCase(shippingStatus) : 'Pending Shipment Details'
  const isShippingResolution = getIsShippingResolution(order)
  return (
    <BadgeWrapper>
      <TopRow>
        {!isShippingResolution && !isMobile && <Icon icon={Package} color={COLOR.NEUTRAL[600]} />}
        {!isShippingResolution && <StyledSubheading data-cy="sp-card-subheading">Shipping Protection</StyledSubheading>}
        {!isLoading && !isShippingResolution && (
          <Badge
            text={mapContractStatusBadge(status || '')}
            color={shipmentDisplayDetails.badgeColor}
            data-cy="sp-card-badge"
            icon={isMobile ? Package : undefined}
          />
        )}
        {!isLoading && isShippingResolution && (
          <Badge
            text={shippingResolutionBadgeText}
            color={shipmentDisplayDetails.badgeColor}
            data-cy="sp-card-badge"
            icon={Package}
          />
        )}
      </TopRow>
      {!isLoading && hasActiveShipmentClaim && (
        <ClaimInProgressWrapper>
          <Badge
            emphasis="low"
            text={shipmentDisplayDetails.badgeDetails}
            data-cy="sp-card-claim-progress-badge"
            color="green"
          />
        </ClaimInProgressWrapper>
      )}
    </BadgeWrapper>
  )
}

const BadgeWrapper = styled.div({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const TopRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const StyledSubheading = styled(Subheading)({
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
})

const ClaimInProgressWrapper = styled.div({
  alignSelf: 'flex-start',
})
