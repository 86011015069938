import type {
  AuthStartRequest,
  AuthStartResponse,
  AuthFinishRequest,
  AuthFinishResponse,
  ConsumerProfileSearchResponse,
  TrackingResponse,
  SanitizedEvent,
  TrackingRequest,
  OrdersListQueryParams,
  OrdersListByShipmentsResponse,
} from '@extend-myextend/types'
import { faker } from '@faker-js/faker'
import type { clientTypes as EMSClientTypes } from '@extend-entitlements-mgmt/client'

export type { AuthFinishRequest, AuthFinishResponse, AuthStartRequest, AuthStartResponse, TrackingResponse, TrackingRequest, OrdersListQueryParams, OrdersListByShipmentsResponse }

export type ConsumerProfileResponse = ConsumerProfileSearchResponse

export type ConsumerProfileUpdateRequest = {
  id: string
  body: Partial<ConsumerProfileSearchResponse>
  accessToken?: string
  apiVersion?: string
}

export type AuthFinishContact = AuthStartRequest

export type MyExtendApiVersion = {
  apiVersion?: string
  accessToken?: string
}


export type MyExtendRequestWithApiVersion<T> = {
  body: T
} & MyExtendApiVersion

export type OrdersListRequest = MyExtendApiVersion & OrdersListQueryParams

export type OrdersListByOrders = EMSClientTypes.EntitlementSearchByOrdersResponse['orders']

export type OrdersListResponse = OrdersListByOrders | OrdersListByShipmentsResponse

export type EntitlementSearchByOrdersResponse = EMSClientTypes.EntitlementSearchByOrdersResponse['orders'][0]

export type EntitlementSearchByProductsResponse = EMSClientTypes.EntitlementSearchByProductsResponse['products'][0]

export type EmsProduct = EntitlementSearchByOrdersResponse['products'][0]

export type EmsProductWithShipmentInfo = EmsProduct & {
  shipmentInfo?: EntitlementSearchByProductsResponse['shipmentInfo']
}

export type EmsEntitlement = NonNullable<EmsProduct['entitlements']>[0]

export type EmsContract = NonNullable<EntitlementSearchByOrdersResponse['contracts']>[string]

/** TODO: Use generators from backend https://helloextend.atlassian.net/browse/POST-2314 */
export function generateSanitizedEvent(overrides?: Partial<SanitizedEvent>): SanitizedEvent {
  return {
    occurredAt: faker.date.recent().toISOString(),
    description: faker.lorem.sentence(),
    cityLocality: faker.location.city(),
    stateProvince: faker.location.state(),
    statusCode: faker.string.alphanumeric(3),
    ...overrides,
  }
}

export function generateTrackingResponse(overrides?: Partial<TrackingResponse>): TrackingResponse {
  return {
    orderMetadata: { loginMethods: ['email', 'phone'] },
    trackingNumber: faker.string.uuid(),
    statusCode: 'DE',
    shipDate: faker.date.recent().toISOString(),
    estimatedDeliveryDate: faker.date.recent().toISOString(),
    actualDeliveryDate: faker.date.recent().toISOString(),
    transactionId: faker.string.uuid(),
    events: [generateSanitizedEvent()],
    ...overrides,
  }
}
