import type { FC } from 'react'
import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import type { ConnectRequest } from '@extend-incredibot/types'
import { useTheme } from '@emotion/react'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import { ModalController } from '@extend/zen'
import { logEvent } from '../../analytics'
import { FileClaimLanguageOptionModal } from '../../components/file-claim-language-option-modal/file-claim-language-option-modal'
import ContactMerchantModal from '../../components/contact-merchant-modal'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import * as selectors from '../../reducers/selectors'
import { useSelector } from 'react-redux'
import { OrderCard } from './order-card/order-card'
import type { OrdersListByShipmentsResponse } from '@extend-myextend/types'

export type OrdersListProps = {
  emsOrders: OrdersListByShipmentsResponse
}

const OrdersList: FC<OrdersListProps> = ({ emsOrders }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCustomerSupportModalOpen, setIsCustomerSupportModalOpen] = useState(false)
  const [isFilingClaim, setIsFilingClaim] = useState(false)
  const [connectData, setConnectData] = useState<Partial<ConnectRequest>>()
  const theme = useTheme()
  const { isExtend } = theme.merchantConfiguration

  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { isEmailVerified, isPhoneNumberVerified, hasAccountInfo } = getAccountInfo(consumerProfile)

  const isMissingRequiredProfileField = hasAccountInfo && (!isEmailVerified || !isPhoneNumberVerified)

  const history = useHistory()
  const { locale } = useIntl()

  const handleModalOpen = (): void => {
    setIsModalOpen(true)
  }

  const handleModalClose = (): void => {
    setIsModalOpen(false)
  }

  const handleFileClaim = useCallback((): void => {
    if (!connectData?.contractId && !connectData?.orderId) return

    logEvent('My Plans - Order Card - Clicks', 'File a Claim')
    history.push(!isExtend ? `/${locale}/my_claims/file-claim` : `/${locale}/kaley`, {
      orderId: connectData?.orderId,
      contractId: connectData?.contractId,
      lineItemIds: connectData?.lineItemIds,
      shipmentId: connectData?.shipmentId,
      trackingId: connectData?.trackingId,
    })
  }, [connectData, history, locale])

  const handleFileClaimClick = (
    handlerArgs: Partial<ConnectRequest> & {
      isMerchantOwnedIntake?: boolean
    },
  ): void => {
    setConnectData({
      orderId: handlerArgs.orderId,
      contractId: handlerArgs.contractId,
      lineItemIds: handlerArgs.lineItemIds,
      shipmentId: handlerArgs.shipmentId,
      trackingId: handlerArgs.trackingId,
    })
    if (
      handlerArgs.isMerchantOwnedIntake &&
      (handlerArgs.contractId === connectData?.contractId || handlerArgs.orderId === connectData?.orderId)
    ) {
      setIsCustomerSupportModalOpen(true)
    } else if (['fr-CA', 'en-CA'].includes(locale)) {
      handleModalOpen()
    } else {
      setIsFilingClaim(true)
    }
  }

  useEffect(() => {
    if (isFilingClaim && (connectData?.contractId || connectData?.orderId)) {
      handleFileClaim()
      setIsFilingClaim(false)
    }
  }, [isFilingClaim, connectData, handleFileClaim])

  const selectedOrder = emsOrders.find(
    (emsData) =>
      emsData.id === connectData?.orderId ||
      (emsData.contracts?.length && emsData.contracts.find((contract) => contract.id === connectData?.contractId)),
  )
  return (
    <OrdersWrapper data-cy="orders-list">
      {emsOrders.map((data) => {
        return (
          <OrderCard
            key={data.id}
            order={data}
            onFileClaimClick={handleFileClaimClick}
            isMissingRequiredProfileField={isMissingRequiredProfileField}
          />
        )
      })}
      <FileClaimLanguageOptionModal
        isModalOpen={isModalOpen}
        onDismiss={handleModalClose}
        onContinue={handleFileClaim}
      />
      <ModalController isOpen={isCustomerSupportModalOpen}>
        <ContactMerchantModal
          onDismissRequest={() => setIsCustomerSupportModalOpen(false)}
          storeId={selectedOrder?.store.id as string}
        />
      </ModalController>
    </OrdersWrapper>
  )
}

const OrdersWrapper = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '24px',
  [bp.mobile]: {
    justifyContent: 'center',
  },
  [bp.desktop]: {
    justifyContent: 'flex-start',
  },
})

export { OrdersList }
