import type { PhotoRequirement } from '@extend-claims-management/types'
import type { ServiceType } from './plan'
import type { Fulfillment } from './fulfillment'
import type { FulfillmentActivity } from './fulfillment-activity'
import type { ProductSettings } from './product'
import type { Money } from './currency'

/** warrant claim errors */
export enum ClaimCode {
  invalid_contract_status = 'invalid_contract_status',
  no_adh_coverage = 'no_adh_coverage',
  occurred_before_coverage = 'occurred_before_coverage',
  occurred_after_coverage = 'occurred_after_coverage',
  occurred_future_date = 'occurred_future_date',
  // even if the incident occurred within coverage
  // if can only be reported so far past the coverage end date.
  reported_after_coverage = 'reported_after_coverage',
  // reported before covrage case is covered by combination of occured before coverage and occured on future date.
  no_explicit_failure_type = 'no_explicit_failure_type',
  // an active `approved` or `review` claim exists on the contract
  active_claim_found = 'active_claim_found',
  // tire or wheel plan requires manual review
  is_tire_or_wheel = 'is_tire_or_wheel',
  images_required = 'images_required',
  fraud_risk = 'fraud_risk',
}

export type ClaimProductStatus = 'functional' | 'nonfunctional' | 'intermittent' | 'lost' | 'no-power'

export type ClaimStatus = 'approved' | 'fulfilled' | 'closed' | 'denied' | 'review' | 'pending_adjudication'

export type ClaimClosedResolution = 'no_service' | 'defective_product_not_shipped'
export type ClaimDenialReason =
  | 'occurred_outside_coverage'
  | 'failure_not_covered'
  | 'police_report_not_provided'
  | 'images_not_provided'
  | 'under_manufacturer_warranty'

export interface ClaimClosedMetaData {
  resolution: ClaimClosedResolution
  closedAt: number
}

export enum ClaimSelectStatus {
  approved = 'approved',
  fulfilled = 'fulfilled',
  closed = 'closed',
  denied = 'denied',
  review = 'review',
  pending = 'pending',
  pending_adjudication = 'pending_adjudication',
}

export type AdminSyncStatus = 'complete' | 'failed' | 'pending'

export type ShippingStatus = 'notScanned' | 'scanned' | 'received' | 'inspected'

export enum FailureType {
  // If the user answers no to all FailureTypes
  unanswered = 'unanswered',
  // Non - Accidental FailureTypes
  electricalFailure = 'electricalFailure',
  mechanicalFailure = 'mechanicalFailure',
  // Accidental FailureTypes
  road_hazard = 'road_hazard',
  waterDamage = 'waterDamage',
  dropped = 'dropped',
  aluminum_finish_scratch = 'aluminum_finish_scratch',
  animal_damage = 'animal_damage',
  ballpoint_ink_stain = 'ballpoint_ink_stain',
  bleach_stain = 'bleach_stain',
  box_spring_handle_damage = 'box_spring_handle_damage',
  broken_zipper_button = 'broken_zipper_button',
  burn = 'burn',
  burn_heat_mark = 'burn_heat_mark',
  burning_mech_component_damage = 'burning_mech_component_damage',
  color_fade_loss = 'color_fade_loss',
  color_fade_loss_change = 'color_fade_loss_change',
  electrical_component_damage = 'electrical_component_damage',
  electrical_component_failure = 'electrical_component_failure',
  fabric_gazebo_cover_sling_frame_damage = 'fabric_gazebo_cover_sling_frame_damage',
  finish_check_crack_bubble_peel = 'finish_check_crack_bubble_peel',
  fire = 'fire',
  foam_loss = 'foam_loss',
  food_beverage_stain = 'food_beverage_stain',
  frame_damage = 'frame_damage',
  frame_spring_mech_motor_lever_damage = 'frame_spring_mech_motor_lever_damage',
  human_pet_body_fluid_waste_stain = 'human_pet_body_fluid_waste_stain',
  incline_recline_heat_vibrate_mech_damage = 'incline_recline_heat_vibrate_mech_damage',
  liquid_mark_ring = 'liquid_mark_ring',
  loose_joint = 'loose_joint',
  material_scuffing_scrapes_fraying_pilling = 'material_scuffing_scrapes_fraying_pilling',
  mirror_glass_chip_scratch_break = 'mirror_glass_chip_scratch_break',
  mirror_silvering_loss = 'mirror_silvering_loss',
  mold_mildew = 'mold_mildew',
  nail_polish_and_remover_stain_damage = 'nail_polish_and_remover_stain_damage',
  non_damage_breakage = 'non_damage_breakage',
  non_fire_burn = 'non_fire_burn',
  odor = 'odor',
  other_puncture_tear_rip = 'other_puncture_tear_rip',
  other_water_damage = 'other_water_damage',
  perspiration_hair_body_oils = 'perspiration_hair_body_oils',
  pet_damage = 'pet_damage',
  puncture_cut_tear_rip = 'puncture_cut_tear_rip',
  rocker_swivel_glide_recline_mech_breakage = 'rocker_swivel_glide_recline_mech_breakage',
  rug_fringe = 'rug_fringe',
  rug_pilling_fraying = 'rug_pilling_fraying',
  scratch_gouge_chip = 'scratch_gouge_chip',
  seam_separation = 'seam_separation',
  six_inch_border_tear = 'six_inch_border_tear',
  spring_coil_puncture_tear_rip = 'spring_coil_puncture_tear_rip',
  table_arc_umbrella_mech_damage = 'table_arc_umbrella_mech_damage',
  table_top_breakage = 'table_top_breakage',
  umbrella_rib_damage = 'umbrella_rib_damage',
  vermin = 'vermin',
  weld_breakage = 'weld_breakage',

  // Jewelry FailureTypes
  broken_bent_or_worn_prong_clasp_or_hinge = 'broken_bent_or_worn_prong_clasp_or_hinge',
  knotted_or_broken_link_in_necklace_or_bracelet = 'knotted_or_broken_link_in_necklace_or_bracelet',
  broken_earring_backing = 'broken_earring_backing',
  lost_earring_pin_or_post = 'lost_earring_pin_or_post',
  stretched_pearl_necklace = 'stretched_pearl_necklace',
  chipped_or_cracked_stone_or_center_stone = 'chipped_or_cracked_stone_or_center_stone',
  lost_center_or_side_or_enhancement_stone = 'lost_center_or_side_or_enhancement_stone',
  chain_soldering = 'chain_soldering',
  lost_jewelry_item = 'lost_jewelry_item',
  stolen_jewelry_item = 'stolen_jewelry_item',
  lost_or_stolen_jewelry_item = 'lost_or_stolen_jewelry_item',
  servicing_resizing = 'servicing_resizing',
  servicing_reshanking = 'servicing_reshanking',
  servicing_refinishing = 'servicing_refinishing',
  servicing_rhodium_plating = 'servicing_rhodium_plating',
  servicing_resetting = 'servicing_resetting',
  // Tire/Wheel FailureTypes
  road_hazard_blowout = 'road_hazard_blowout',
  tire_pressure_loss = 'tire_pressure_loss',
  shipment_lost = 'shipment_lost',
  shipment_stolen = 'shipment_stolen',
  shipment_damaged = 'shipment_damaged',
  multiple_shipments_in_order = 'multiple_shipments_in_order',
  no_shipments_in_order = 'no_shipments_in_order',
  invalid_tracking_number = 'invalid_tracking_number',
}

export enum FailureLocation {
  in_transit = 'in_transit',
  on_site = 'on_site',
  other = 'other',
}

export enum ClaimProductSection {
  adjustable_bed_base = 'adjustable_bed_base',
  area_rug = 'area_rug',
  fabric_area_cushion_set = 'fabric_area_cushion_set',
  fabric_vinyl_leather = 'fabric_vinyl_leather',
  indoor_hard_surface = 'indoor_hard_surface',
  mattress = 'mattress',
  outdoor_hard_surface = 'outdoor_hard_surface',
  propane_fire_pit = 'propane_fire_pit',
  umbrella = 'umbrella',
}

export interface ClaimAddress {
  address1: string
  address2?: string
  city: string
  countryCode: string
  postalCode: string
  provinceCode: string
}

export interface ClaimCustomerInformation {
  name: string
  phone?: string
  email: string
  shippingAddress: ClaimAddress
}

export interface ClaimFailureInformation {
  [key: string]: string | string[] | number | boolean | undefined
  productCondition: ClaimProductStatus
  description: string
  occurredAt: number
  IncidentDate?: number | undefined
  FailureType?: FailureType | undefined
  Products?: string[]
  failureType: FailureType
  failureLocation?: FailureLocation
  productSection?: ClaimProductSection
}

export enum FraudulentActivityType {
  multiple_retries_short_duration = 'multiple_retries_short_duration', // Multiple retries on a claim filing in a short period of time
  suspicious_behavior = 'suspicious_behavior', // Suspicious Consumer behavior
  multiple_authentication_failures = 'multiple_authentication_failures',
  repurposing_contract_for_related_product = 'repurposing_contract_for_related_product', // Repurposing contract for related product
  incorrect_product_returned = 'incorrect_product_returned',
  failed_claim_with_mfr = 'failed_claim_with_mfr', // Retry with Extend after failed claim with MFR
  other = 'other',
}

export enum RiskLevel {
  low = 'low',
  medium = 'medium',
  high = 'high',
  certain = 'certain',
}

export interface FraudulentActivity {
  description?: string
  riskLevel: RiskLevel
  type: FraudulentActivityType
}

export interface FraudRiskDetails {
  riskScore: number
  riskReasoning: string[]
  reviewNotes: string
}

export interface ClaimBaseFields {
  customer: ClaimCustomerInformation
  incident: ClaimFailureInformation
  supportTicketNumber?: number
  // TODO: set to required when implemented
  filedBy?: ClaimFiler
  products?: ClaimProduct[]
  shipmentId?: string
  shippingCost?: Money
  tax?: Money
  totalValue?: Money
}

export interface ClaimProduct {
  referenceId: string
  lineItemId: string
  limitOfLiability: Money
  title: string
  components?: ClaimProductComponent[]
}

export interface ClaimProductComponent {
  referenceId: string
  productReferenceId: string
  value: Money
  title: string
}

export enum ClaimType {
  EXTENDED_WARRANTY = 'extended_warranty',
  SHIPPING_PROTECTION = 'shipping_protection',
  CATEGORY = 'category',
}

export type PendingClaimDisposition = 'ship_product'

export interface ClaimValidationResponse {
  rulesetId: string
  ruleId: string
  version: number
  status: 'approved' | 'denied' | 'review'
}

export interface Claim extends ClaimBaseFields {
  adminClaimId?: string
  adminContractId: string
  adminSyncError?: string
  adminSyncStatus: AdminSyncStatus
  closedMetaData?: ClaimClosedMetaData
  contractId: string
  createdAt: number
  approvedAt?: number
  fulfilledAt?: number
  deniedAt?: number
  id: string
  fraudRiskDetails?: FraudRiskDetails
  fraudulentActivity?: FraudulentActivity
  reopened?: boolean
  reportedAt: number
  sellerId: string
  serviceType: ServiceType
  sessionId?: string
  shippingLabel?: string
  shippingStatus?: ShippingStatus
  status: ClaimStatus
  syncedAt?: number
  updatedAt: number
  validationError?: ClaimCode
  adjudicationType?: AdjudicationType
  adjudicationCategory?: string
  type?: ClaimType
  items?: ClaimItem[]
  pendingClaimDisposition?: PendingClaimDisposition | null
  conversationId?: string
  ruleset?: ClaimValidationResponse
  denialReason?: ClaimDenialReason
  photoRequirements?: PhotoRequirement[]
}

export type ClaimItem = ClaimItemProduct

export interface ClaimItemBase {
  id: string
  referenceItemId: string // id of the associated item/product
  referenceItemType: ClaimItemType
  claimId: string
  quantity: number
  title: string
  createdAt: number
  updatedAt: number // ClaimItem is immutable so this should always equal createdAt
}

interface ClaimItemProduct extends ClaimItemBase {
  referenceItemType: 'product'
  category?: string
  currencyCode: SupportedCurrencyCode
  listPrice: number
  purchasePrice?: number
  imageUrl?: string
  shipmentId?: string // Will not be required for 'extended-warranty' claims
  servicingSettings?: ProductSettings
  sellerId: string
}

// This doesn't seem like the best home for this but didn't want to add the currency package
export type SupportedCurrencyCode = 'AUD' | 'CAD' | 'EUR' | 'GBP' | 'NZD' | 'USD'

export interface ClaimFiler {
  firstName?: string
  lastName?: string
  email?: string
  accountId?: string
}

export interface ClaimCreateRequest extends ClaimBaseFields {
  supportTicketNumber?: number
  adjudicationType?: AdjudicationType
  itemsToCreate?: ClaimItemCreateRequestProps[]
  contractId?: string
}

export type ClaimItemType = 'product'

export interface ClaimItemCreateRequestProps {
  referenceItemId: string // id of the associated item/product
  referenceItemType: ClaimItemType
  quantity: number
}

export type ClaimSearchKey = keyof ClaimSearchOptions

interface ClaimSearchOptions {
  status?: string
  adminSyncStatus?: string
  customerEmail?: string
  contractId?: string
  shippingStatus?: string
  claimId?: string
}

export type GetClaimsReponse = Claim & {
  fulfillments: Array<Fulfillment & { fulfillmentActivities: FulfillmentActivity[] }>
}

export enum AdjudicationType {
  // customer did something to break it
  accident = 'accident',
  // it broke by itself
  random_failure = 'random_failure',
}

export type AssignedUser = {
  id: string
  firstName: string
  lastName: string
  email: string
}

// This is named as such to avoid confusion with auth service claims and contract service claims
export interface InsuranceClaim extends Claim {
  assignedUser?: AssignedUser
  userAssignedAt?: number
  photoRequirements?: PhotoRequirement[]
}
